<template>
  <div>
    <Breadcrumb
      :current-page-title="titleBreadcrumb"
      :intermediary-path="intermediaryPath"
    />
    <RevContainer class="w-auto">
      <div class="flex flex-col items-center justify-center">
        <Heading class="mb-56 md:mb-72" :title="title" />
      </div>

      <div v-if="hasCta" class="mb-56" :class="CTA_CLASSES">
        <PDFLinkCmp v-if="pdf" v-bind="pdf" />
        <LegalDocumentsModal
          v-if="hasDocumentsLinks"
          :documents="legalDocuments"
          :title="documentLinksLabel"
        >
          {{ documentLinksLabel }}
        </LegalDocumentsModal>
      </div>

      <BlockRenderer
        v-for="(block, index) in blocks"
        :id="block.id"
        :key="block.id"
        :block-position="index"
        :data-test="block.id"
        :hidden-devices="block.hiddenDevices"
        :props="block.props"
        :type="block.type"
      />

      <div v-if="displayBottomLineOfButtons" class="mb-24" :class="CTA_CLASSES">
        <PDFLinkCmp v-if="pdf" v-bind="pdf" />
        <LegalDocumentsModal
          v-if="hasDocumentsLinks"
          :documents="legalDocuments"
          :title="documentLinksLabel"
        >
          {{ documentLinksLabel }}
        </LegalDocumentsModal>
      </div>
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { navigateTo } from '#imports'
import { type ComputedRef, computed, onBeforeMount, reactive } from 'vue'

import type { Pdf } from '@backmarket/http-api/src/api-specs-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'

import { CMS } from '~/scopes/cms/routes-names'
import { CMS_PAGE_TYPE_BY_ROUTE_NAME } from '~/scopes/cms/service/content-service.config'
import BlockRenderer from '~/scopes/cms/shared-components/BlockRenderer/BlockRenderer.vue'
import Breadcrumb from '~/scopes/cms/shared-components/Breadcrumb/Breadcrumb.vue'
import type { Crumb } from '~/scopes/cms/shared-components/Breadcrumb/crumb'
import { getIntermediaryPath } from '~/scopes/cms/shared-components/Breadcrumb/helpers/get-intermediary-path'
import Heading from '~/scopes/cms/shared-components/Heading/Heading.vue'

import { LegalDocumentsModal } from '../../components/LegalDocumentsModal'
import PDFLinkCmp from '../../components/PDFLink/PDFLink.vue'

import translations from './LegalTemplate.translations'
import { type LegalTemplateProps } from './legal-template.props'

const props = withDefaults(defineProps<LegalTemplateProps>(), {
  legalDocuments: () => reactive([]),
})

const i18n = useI18n()

const intermediaryPath: ComputedRef<Crumb[]> = computed(() =>
  getIntermediaryPath({
    pageType: CMS_PAGE_TYPE_BY_ROUTE_NAME[CMS.LEGAL_PAGE],
    parentPageInfos: props.parentPageInfos,
  }),
)

const hasDocumentsLinks = computed(() => !isEmpty(props.legalDocuments))

const hasCta = computed(() => !isEmpty(props.pdf) || hasDocumentsLinks.value)

const CTA_CLASSES =
  'flex w-full gap-16 md:w-auto justify-center items-center md:gap-24'

function shouldOpenPDFDocument(
  legalProps: LegalTemplateProps,
): legalProps is LegalTemplateProps & Required<{ pdf: Pdf }> {
  return (
    !isEmpty(legalProps.pdf) &&
    isEmpty(legalProps.blocks) &&
    legalProps.pageType ===
      CMS_PAGE_TYPE_BY_ROUTE_NAME[CMS.LEGAL_PREVIOUS_VERSION]
  )
}

const displayBottomLineOfButtons = computed(
  () => hasCta.value && props.blocks?.length > 0,
)

const documentLinksLabel = i18n(translations.documentsLinks)

function openPDFDocument(pdf: Pdf) {
  navigateTo(pdf.url, { external: true })
}

onBeforeMount(() => {
  if (shouldOpenPDFDocument(props)) openPDFDocument(props.pdf)
})
</script>

import { type Country } from '@backmarket/http-api'

import { COUNTRIES_WITH_LAST_NAME_FIRST_NAME_ORDER } from '../../constants/name'
/**
 * Combines first name and last name into a full name where order depends on the
 * country code.
 */
export function getFullName(
  countryCode: Country | string,
  firstName: string,
  lastName: string,
): string {
  const name = COUNTRIES_WITH_LAST_NAME_FIRST_NAME_ORDER.includes(countryCode)
    ? `${lastName} ${firstName}`
    : `${firstName} ${lastName}`

  return name.trim()
}

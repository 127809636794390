<template>
  <RevModal :name="modalName" :title="title" :variant="variant">
    <template #trigger="{ open }">
      <CmsButtonBase variant="secondary" width="fixed" @click="open">
        <slot></slot>
      </CmsButtonBase>
    </template>

    <template #body="{ close }">
      <ul class="list-none py-24">
        <li
          v-for="doc in documents"
          :key="doc.id"
          class="flex items-center py-20"
        >
          <IconBill class="mr-8" />
          <RevLink :to="doc.link?.href" @click="close">
            {{ doc.title }}
          </RevLink>
        </li>
      </ul>
    </template>
  </RevModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type LegalDocuments } from '@backmarket/http-api/src/api-specs-content'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { RevLink } from '@ds/components/Link'
import { RevModal } from '@ds/components/Modal'
import { IconBill } from '@ds/icons/IconBill'

withDefaults(
  defineProps<{
    title: string
    documents: LegalDocuments
    modalName?: string
  }>(),
  { modalName: 'legal-documents-modal' },
)

const breakpoint = useBreakpoint()
const variant = computed(() =>
  breakpoint.value > 1 ? 'panel' : 'confirmation',
)
</script>
